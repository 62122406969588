
























































































































































































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/includes/helpViews/editorButtonsHelpView.vue'
import Placeholders from '@/mixins/placeholders/placeholders'
import ReportCommandHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/ReportCommandHandlerModule/ReportCommandHandlerModuleBuilder'
import ModuleConfigSettings from '../components/ModuleConfigSettings.vue'
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue'
import { AddAdminType } from '@/includes/types/AddAdmin/Enums'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleEnableSwitch from '@/components/Modules/components/ModuleEnableSwitch.vue'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";

import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  computed: {
    ModuleManagerState() {
      return ModuleManagerState
    }
  },
  components: {
    ModuleEnableSwitch,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    MultiMessageEditorWithMediaInput,
    SwitchInput,
    NumberInput,
    NestedContent,
    AddAdmin,
    ConfigField
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView,
      AddAdminType
    }
  },

})
export default class ReportCommandHandlerModuleView extends Mixins(InputSetups, Placeholders, ModuleBindings) {
  @VModel() module!: ReportCommandHandlerModuleBuilder
}
